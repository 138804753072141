<template>
  <div class="container col-md-7 col-sm-12 mt-2 sm-container">
    <h1 class="col-12 text-center mb-4 mt-sm-3 header-title">BTCHashMines </h1>
    <h5 class="col-12 text-center mb-5 header-desc">Status and Information</h5>

    <div class="col-12 full-col-12 mb-5">
      <div class="list-group online_list">
    
        <a
        v-for="service in services"
        :key="service.id"
        href="javascript:void(0)"
        class="service_li list-group-item list-group-item-action"
        :data-id="service.id"
      >
        {{ service.name }}
        <span
          :class="['badge', 'float-right', getPulseClass(service.status), getStatusClass(service.status)]"
        >
          {{ service.status }}
        </span>
      </a>
    </div>
  </div>

  <div class="col-12 full-col-12 mt-4">
    <div class="card">
      <div class="card-body">
          <div class="col-12">
              <h4 class="mt-3">User Growth Chart</h4>

              <div class="row stats_area mt-5 mb-5">
                  <div class="col-4">
                      <span class="lg_number">
                        <ICountUp
                            :delay="delay"
                            :endVal="userCount"
                            :options="options"
                            @ready="onReady"
                          />
                      </span>
                      Users count
                  </div>
                  <div class="col-4">
                      <span class="lg_number">{{userLast}}</span>
                      Users count Last 24 hours
                  </div>
                  <div class="col-4">
                      <span class="lg_number">{{parseFloat(averageLast24Hours).toFixed(2)}} %</span>
                      Average Last 24 hours
                  </div>
              </div>
          </div>

        <UserGrowthChart />

      </div>
    </div>
  </div>

  <div class="col-12 full-col-12 mt-4">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <h4 class="mt-3">Stats</h4>
          <div class="row stats_area mt-5 mb-5">
                  <div class="col-4">
                      <span class="lg_number">
                        <ICountUp
                            :delay="delay"
                            :endVal="activeHashRate"
                            :options="options"
                            @ready="onReady2"
                          /> TH/s
                      </span>
                      Active HashRate
                  </div>
                  <div class="col-4">
                      <span class="lg_number">
                        <ICountUp
                            :delay="delay"
                            :endVal="minedBitcoins"
                            :options="options2"
                            @ready="onReady3"
                          />
                        BTC
                      </span>
                      Amount of Bitcoins mined
                  </div>
                  <div class="col-4">
                      <span class="lg_number">
                        <ICountUp
                            :delay="delay"
                            :endVal="payouts"
                            :options="options2"
                            @ready="onReady4"
                          />
                          BTC
                      </span>
                      Amount of Bitcoins withdrawn
                  </div>
              </div>
        </div><!-- col-12 -->

      </div><!-- body -->
    </div>
  </div>

    <!-- </div> -->


  </div>
</template>

<script>
// @ is an alias to /src
import UserGrowthChart from '@/components/UserGrowthChart.vue';
import axios from 'axios';
import ICountUp from 'vue-countup-v2';
export default {
  name: 'HomeView',
  components: {
    UserGrowthChart,
    ICountUp
  },
  data() {
      return {
        previousUserCount: 0,
        userCount: 0,
        activeHashRate: 0,
        previousHashRate: 0,
        minedBitcoins: 0,
        previousMinedBitcoins: 0,
        previouswithdrawn: 0,
        payouts: 0,
        averageLast24Hours: 0,
        userLast: 0,
        interval: null,
        firstLoad: true,
        delay: 0,
        options: {
          separator: '',
          decimal: '.',
          prefix: '',
          suffix: ''
        },
        options2: {
          decimalPlaces: 8, // Número de decimales a mostrar
          duration: 2, // Duración de la animación en segundos
          separator: ',', // Separador de miles
          decimal: '.', // Separador decimal
        },
        services: []
      };
    },
    beforeMount() {
    this.interval = setInterval(this.fetchUserData, 60000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    methods: {
      onReady(instance) {
      if (this.previousUserCount !== this.userCount) {
        instance.update(this.userCount);
      }
    },
    onReady2(instance) {
      if (this.previousHashRate !== this.activeHashRate) {
        instance.update(this.activeHashRate);
      }
    },
    onReady3(instance) {
      if (this.previousMinedBitcoins !== this.minedBitcoins) {
        instance.update(this.minedBitcoins);
      }
    },
    onReady4(instance) {
      if (this.previouswithdrawn !== this.payouts) {
        instance.update(this.payouts);
      }
    },
    fetchServiceStatus() {
      axios
        .get('/status.json')
        .then(response => {
          this.services = response.data.services;
        })
        .catch(error => {
          console.error('Error fetching service status:', error);
        });
    },
    getPulseClass(status) {
      return status === 'ONLINE' ? 'pulse-glow-success' : 'pulse-glow-danger';
    },
    getStatusClass(status) {
      return status === 'ONLINE' ? 'bg-success' : 'bg-danger';
    },
    fetchUserData() {
      axios.get(`${process.env.VUE_APP_API_URL}stats/user-stats`)
        .then(response => {
          const data = response.data;
          //this.userCount = data.total_user_count;
          const newUserCount = data.total_user_count;
          this.previousUserCount = this.userCount;
          this.userCount = newUserCount;

          const newHashRate = data.total_user_hash_rate;
          this.previousHashRate = this.activeHashRate;
          this.activeHashRate = newHashRate;

          const newmined_bitcoins = data.mined_bitcoins;
          this.previousMinedBitcoins = this.minedBitcoins;
          this.minedBitcoins = newmined_bitcoins;

          const newpayouts = data.withdraws;
          this.previouswithdrawn = this.payouts;
          this.payouts = newpayouts;

          this.averageLast24Hours = data.average_users_last_24_hours;
          this.userLast =  data.user_count_last_24_hours;
          if (this.firstLoad) {
            this.firstLoad = false; // Desactiva la bandera después de la primera carga
          }
        })
        .catch(error => {
          console.error('Error fetching user stats:', error);
        });
    }
  },
  mounted() {
    this.fetchUserData();
    this.fetchServiceStatus()
  }
}
</script>
<style scoped>

</style>
