<template>
    <div>
      <LineChartGenerator 
      v-if="loaded"
      :data="chartData" 
      :options="chartOptions" />
    </div>
  </template>
  
<script>
//import { Line as LineChartGenerator } from 'vue-chartjs';
//import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import axios from 'axios';
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale
)
//ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, TimeScale);
  
  export default {
    components: {
        LineChartGenerator
    },
    data() {
      return {
        loaded: false,
        chartData: {
        labels: [],
        datasets: [
          {
            label: 'User Growth',
              backgroundColor:['rgba(47, 206, 30, 0.92)'],
              borderColor:['rgb(47, 171, 34)'],
              borderWidth:1,
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Users'
            }
          }
        }
      }
    }
    
      
    },
    methods: {
      fetchUserData() {
        this.loaded = false;
        axios.get(`${process.env.VUE_APP_API_URL}stats/user-growth`)
          .then(response => {
            
            const data = response.data;
            //console.log(data);
            const labels = data.map(record => record.date);
            const users = data.map(record => record.user_count);
            //console.log(users);
            //console.log(labels);
            this.chartData.labels = labels;
            this.chartData.datasets[0].data = users;
            this.loaded = true;
          //this.datacollection.labels = data.map(record => new Date(record.date));
         // this.chartData.datasets[0].data = data.map(record => record.user_count);
          })
          .catch(error => {
            console.error('Error fetching user growth data:', error);
          });
      }
    },
    mounted() {
      this.fetchUserData();
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    position: relative;
    height: 170px;
    width: 100%;
}
  </style>